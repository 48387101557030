import React from "react";
import ALink from "../../../components/merged/Alink";

import Image from "../../../components/merged/Image";
import { TinyTitle } from "../../../components/shared/FontCollection";
import "./ProductCard.css";

export default function ProductCard({ id, mainPicture, title, price }) {
    return <div className="product-card">
        <div className="product-card__content">
            <ALink to={`/products/${id}`}>
                <div className="product-card__image">
                    <Image {...mainPicture} className="product-card__image-img" />
                </div>
            </ALink>
            <ALink to={`/products/${id}`}>
                <div className="product-card__title">
                    <TinyTitle>{title}</TinyTitle>
                </div>
            </ALink>
            <div className="product-card__price">
                <p>{price}</p>
            </div>
        </div>
    </div>
}