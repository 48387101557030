import * as React from "react"
import { graphql } from "gatsby";

import Seo from "../components/shell/seo"
import LayoutWrapper from "../template/LayoutWrapper";
import ProductsContent from "../pageContents/products/ProductsContent";
import { getSeoData } from "../services/seo.service";

const seoData = {
  "seoTranslations": [
    {
      "language": "jp",
      "title": "MORIMAの最高の製品",
      "keywords": "株式会社MORIMA、KANSAIBO、SC LABO、プラセンタ、化粧品製造販売業、越境ECベトナム進出サポート",
      "description": "専門家によって作られた製品"
    },
    {
      "language": "en",
      "title": "MORIMA's best products",
      "keywords": "Japanese cosmetic and healthcare products , nail salon , esthe salon ",
      "description": "Products made by professionals"
    }
  ]
};

function ProductsPage(props) {
  return <LayoutWrapper pageContext={props.pageContext}>
    <Seo {...getSeoData(props, seoData)} />
    <ProductsContent data={props.data.allProductsJson.nodes} categorys={props.data.allCategoryJson.nodes} />
  </LayoutWrapper>
}

export default ProductsPage;

export const query = graphql`
  {
    allProductsJson {
      nodes {
        categoryId
        id
        productsCode
        status
        seoTranslations {
          description
          keywords
          language
          title
        }
        translations {
          information
          language
          price
          summary
          title
          usage
        }
        slideImage {
          id
          sort
          translations {
            description
            language
            title
          }
          image {
            alt
            src {
              childImageSharp {
                fixed {
                  src
                }
              }
            }
          }
        }
        mainPicture {
          alt
          src {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
    allCategoryJson {
      nodes {
        id
        sort
        productCategoryTitle
        translations {
          title
          language
          description
        }
        image {
          alt
          src {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
  }
`