export function normalizeTranslations(language, containingTranslationsObject, translationObjectName = "translations") {
    if (!containingTranslationsObject || !containingTranslationsObject[translationObjectName]) return;

    const translationByLanguage = containingTranslationsObject[translationObjectName].find(t => t.language === language);
    containingTranslationsObject = {
        ...containingTranslationsObject,
        ...translationByLanguage
    };

    delete containingTranslationsObject[translationObjectName];

    return containingTranslationsObject;
}