import React from "react";

import ProductsByCategory from "./sections/ProductsByCategory";

export default function ProductsContent(props) {
    return <React.Fragment>
        {
            props.categorys.map((category, key) => {
                return <ProductsByCategory
                    key={key}
                    title={category.productCategoryTitle}
                    data={props.data.filter(p => p.categoryId === category.id)} />
            })
        }
    </React.Fragment>
}