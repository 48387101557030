import React from "react";

import { useLanguageContext } from "../../../components/shell/LanguageContext";
import { normalizeTranslations } from "../../../services/translationHelper.service";
import ProductCard from "../components/ProductCard";
import SectionTitle from "../components/SectionTitle";
import "./ProductsByCategory.css";

export default function ProductsByCategory({ title, data }) {
    const languageData = useLanguageContext();

    return <section className="products-by-category">
        <div className="products-by-category__content">
            <SectionTitle value={title} />
            <div className="products-by-category__products">
                {
                    data.map((item, key) => <ProductCard key={key} {...normalizeTranslations(languageData.language, item)} />)
                }
            </div>
        </div>
    </section>
}